exports.components = {
  "component---src-pages-101-ogdrop-tsx": () => import("./../../../src/pages/101ogdrop.tsx" /* webpackChunkName: "component---src-pages-101-ogdrop-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-all-cards-tsx": () => import("./../../../src/pages/all-cards.tsx" /* webpackChunkName: "component---src-pages-all-cards-tsx" */),
  "component---src-pages-all-ores-tsx": () => import("./../../../src/pages/all-ores.tsx" /* webpackChunkName: "component---src-pages-all-ores-tsx" */),
  "component---src-pages-bone-dragon-tsx": () => import("./../../../src/pages/bone-dragon.tsx" /* webpackChunkName: "component---src-pages-bone-dragon-tsx" */),
  "component---src-pages-camera-helper-tsx": () => import("./../../../src/pages/camera-helper.tsx" /* webpackChunkName: "component---src-pages-camera-helper-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-nonprofit-cycle-tsx": () => import("./../../../src/pages/nonprofit-cycle.tsx" /* webpackChunkName: "component---src-pages-nonprofit-cycle-tsx" */),
  "component---src-pages-riddle-tsx": () => import("./../../../src/pages/riddle.tsx" /* webpackChunkName: "component---src-pages-riddle-tsx" */),
  "component---src-pages-shirt-drop-01-tsx": () => import("./../../../src/pages/shirt-drop-01.tsx" /* webpackChunkName: "component---src-pages-shirt-drop-01-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-whitelist-tsx": () => import("./../../../src/pages/whitelist.tsx" /* webpackChunkName: "component---src-pages-whitelist-tsx" */)
}

